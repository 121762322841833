import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-06e05e7c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "note-item" };
const _hoisted_2 = {
    key: 0,
    class: "new-day"
};
const _hoisted_3 = { class: "day-break-text" };
const _hoisted_4 = { class: "content" };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { class: "date" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LinkPreview = _resolveComponent("LinkPreview");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($options.newDay($props.note, $props.prev))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(this.formatDate($props.note.createdAt)), 1)
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
                class: "html-note",
                innerHTML: $options.renderNote($props.note, $props.prev)
            }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, _toDisplayString(this.formatTime($props.note.createdAt)), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.note.attachments, (attachment) => {
            return (_openBlock(), _createElementBlock("div", {
                class: "link-preview-wrapper",
                key: attachment.id
            }, [
                _createVNode(_component_LinkPreview, {
                    linkPreview: attachment.metadata
                }, null, 8, ["linkPreview"])
            ]));
        }), 128))
    ]));
}

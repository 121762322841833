import gql from "graphql-tag";

export const ListItemFragment = gql`
  fragment ListItemFields on ListItem {
    id
    snippet
    createdAt
    updatedAt
    todoMetadata {
      hidden
      id
      completed
      completedAt
      createdAt
      updatedAt
    }
  }
`;

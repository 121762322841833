
import Composer from "./Composer.vue";
import NoteList from "./NoteList.vue";
import NotebookControls from "./NotebookControls.vue";
import {
  LognoteQueryFilters,
  TimelineQuery,
  TimelineQueryFilters,
  GetTimelineQueryVariables,
  GetTimelineQuery,
} from "@lognote/common";
import { TIMELINE_QUERY } from "@lognote/common/lib/graphql/GetTimeline";
import { useQuery, useResult } from "@vue/apollo-composable";
import { ref } from "vue";

export default {
  name: "Notebook",
  components: {
    Composer,
    NoteList,
    NotebookControls,
  },

  setup() {
    const filters = ref<LognoteQueryFilters>({});

    const handleUpdate = (updatedVal: LognoteQueryFilters) => {
      filters.value = { ...updatedVal };
    };

    const handleDateChange = (updatedVal: number) => {
      console.log("changing date", updatedVal);
      filters.value = { ...filters.value, startingAt: updatedVal.toString() };
    };

    return { handleUpdate, filters, handleDateChange };
  },
};

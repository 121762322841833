
import { LinkPreviewFieldsFragment } from "@lognote/common";
import { computed } from "vue";

export default {
  name: "LinkPreview",

  props: {
    linkPreview: {
      type: Object as () => LinkPreviewFieldsFragment,
      required: true,
    },
  },
};

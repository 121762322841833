
import { logout } from "../lib/auth";
import { Plus } from "@element-plus/icons-vue";
import {
  CREATE_LIST_MUTATION,
  CreateListResult,
} from "@lognote/common/lib/graphql/CreateList";
import {
  LISTS_QUERY,
  ListQueryResult,
} from "@lognote/common/lib/graphql/GetLists";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Sidenav",
  components: {},

  methods: {
    handleLogout() {
      logout();
    },

    async handleCreate(): Promise<void> {
      await this.mutate({ list: { name: this.input } });
      this.input = "";
      this.dialogVisible = false;
    },

    getRoute(list: { slug: string }) {
      return "/lists/" + list.slug;
    },
  },

  setup() {
    const { result, loading, error } = useQuery<ListQueryResult>(LISTS_QUERY);
    const lists = useResult(result, [], (data) => data.lists);

    const { mutate } = useMutation<
      CreateListResult,
      { list: { name: string } }
    >(CREATE_LIST_MUTATION, {
      update: (cache, result) => {
        const currentData = cache.readQuery<ListQueryResult>({
          query: LISTS_QUERY,
        });

        const currentLists = (currentData && currentData.lists) || [];
        const lists = [...currentLists, result.data?.createList.list];
        cache.writeQuery({ query: LISTS_QUERY, data: { lists } });
      },
    });

    // expose to template
    return {
      dialogVisible: ref(false),
      mutate,
      loading,
      error,
      Plus,
      lists,
      input: ref(""),
    };
  },
});

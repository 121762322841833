
import { User } from "@lognote/common";
import { inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Login",

  components: {},

  setup() {
    const user = ref<null | User>(null);
    const userDataJson = localStorage.getItem("loginResult");
    const token = localStorage.getItem("token");
    if (userDataJson) {
      // redirect to login
      user.value = JSON.parse(userDataJson);
    }

    console.log("setup", user);

    onMounted(() => {
      const r = useRouter();
      if (user.value == null) {
        r.push("/login?target=app");
      } else {
        if (process.env.NODE_ENV === "production") {
          window.open(`com.lilonote.app://authorize?token=${token}`);
        } else {
          window.open(`com.lilonote.app.dev://authorize?token=${token}`);
        }
      }
    });

    return { user };
  },

  mounted() {},
};

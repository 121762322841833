import { LinkPreviewFieldsFragment } from "./fragments";
import gql from "graphql-tag";

export const CREATE_NOTE_MUTATION = gql`
  mutation createLognote($request: LognoteInput!) {
    createLognote(request: $request) {
      success
      message
      lognote {
        id
        userId
        content
        createdAt
        updatedAt
        attachments {
          ... on LinkAttachment {
            id
            data
            type
            metadata {
              ...LinkPreviewFields
            }
          }
        }
        listItems {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              snippet
              list {
                id
              }
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
  ${LinkPreviewFieldsFragment}
`;


import { formatDate } from "../lib/util/date";
import { Search } from "@element-plus/icons-vue";
import {
  GetNotesQuery,
  GetNotesQueryVariables,
  Lognote,
  NOTES_QUERY,
} from "@lognote/common";
import { useLazyQuery, useResult } from "@vue/apollo-composable";
import { ref } from "vue";

export default {
  name: "DateControl",
  props: {
    earliestDate: {
      type: Number,
    },
  },
  emits: {
    change: (searchTerm: string) => {
      return true;
    },
  },
  setup(props: any, { emit }: { emit: (e: "change", arg: string) => void }) {
    const value = ref("");

    const { result, error, loading, fetchMore, load } = useLazyQuery<
      GetNotesQuery,
      GetNotesQueryVariables
    >(NOTES_QUERY, {
      first: 50,
    });

    const notes = useResult(result, [], (res) => {
      return res.lognotes.nodes;
    });

    // const formatTooltip = (val: number) => {
    //   if (val === 0) {
    //     return "Right now";
    //   }

    //   return `${formatDate(Date.now() - val)}`;
    // };

    // const handleChange = (arg: number) => {
    //   emit("change", Date.now() - arg);
    // };

    // const getHeight = () => {
    //   if (sliderParent.value == null) {
    //     return "400px";
    //   }
    //   return sliderParent.value.getBoundingClientRect().height + "px";
    // };

    const shortcuts = [
      {
        text: "Today",
        value: new Date(),
      },
      {
        text: "Yesterday",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: "A week ago",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];

    const handleChange = () => {
      load(undefined, {
        first: 10,
        filters: {
          searchTerm: value.value,
        },
      });
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleChange();
      }
    };

    const choose = (note: Lognote) => {
      if (!note.createdAt) {
        console.error("This error should never be logged");
        return;
      }
      emit("change", note.createdAt);
    };

    return {
      shortcuts,
      value,
      choose,
      handleChange,
      handleKeydown,
      Search,
      notes,
      loading,
    };
  },
};


import NoteEventTarget, { Events } from "../lib/util/NoteEventTarget";
import {
  commit,
  commitNextTick,
  convert,
  parse,
  redo,
  setCursor,
  undo,
} from "../lib/util/editor";
import { useLists } from "../lib/util/query";
import LinkPreviewComponent from "./LinkPreview.vue";
import { Edit, CirclePlus, DArrowRight } from "@element-plus/icons-vue";
import {
  Attachment,
  AttachmentInput,
  CreateLognoteMutation,
  CreateLognoteMutationVariables,
  GetLinkPreviewQuery,
  GetLinkPreviewQueryVariables,
  GetNotesQuery,
  GetNotesQueryVariables,
  LinkPreview,
  LinkPreviewQuery,
  List,
  ListItemInput,
  QueryLinkPreviewArgs,
} from "@lognote/common";
import {
  NOTES_QUERY,
  CREATE_NOTE_MUTATION,
  LINK_PREVIEW_QUERY,
} from "@lognote/common/lib/graphql";
import { useLazyQuery, useMutation, useResult } from "@vue/apollo-composable";
import Showdown from "showdown";
import validator from "validator";
import { computed, defineComponent, ref } from "vue";

const TAG_PATTERN = /#([^\s]*)$/;
let NOTE_TEMP_ID = 0;

export default defineComponent({
  name: "Composer",
  emits: ["change"],
  components: {
    LinkPreviewComponent,
  },

  methods: {
    async handleSave() {
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;

      let md = convert(input).serialize();

      const listItems: ListItemInput[] = [];
      const attachments: AttachmentInput[] = [];

      const liteItems = Array.from<string>(this.selectedListNodes);

      liteItems.forEach((lid) => {
        let matchingNodes = document.querySelectorAll(`[data-list='${lid}']`);
        matchingNodes.forEach((m) => {
          // each node should be an anchor link. We want to grab all of the text in that block

          const parent = m.parentElement;
          if (parent) {
            listItems.push({
              snippet: parent.innerText,
              listId: lid,
            });
          }
        });
      });

      this.disabled = true;

      if (this.hasLinkPreview) {
        attachments.push({
          type: "link",
          // @ts-ignore
          data: this.linkPreview.original,
        });
      }

      // keep around in case it fails
      let innerHTML = input.innerHTML;
      let selected = this.selected;
      let linkPreview = this.linkPreview;
      let selectedListNodes = this.selectedListNodes;

      try {
        this.reset();
        await this.mutate({
          request: {
            note: { content: md },
            listItems,
            attachments,
          },
        });
      } catch (e) {
        console.error(e);
        input.innerHTML = innerHTML;
        input.style.color = "black";
        this.disabled = false;
        this.selected = selected;
        this.linkPreview = linkPreview;
        this.selectedListNodes = selectedListNodes;
      }

      NoteEventTarget.dispatchEvent(new CustomEvent(Events.NOTE_CREATE));

      this.reset();
    },

    setSelected(selected: number) {
      this.selected = selected;
      document.getElementById(this.getListOptionID(selected))?.scrollIntoView();
    },

    getListOptionID(index: number): string {
      return `list-option-${index}`;
    },

    handleKeydown(e: KeyboardEvent) {
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;

      if (e.key === "z" && e.metaKey) {
        if (e.shiftKey) {
          const res = redo();
          // @ts-ignore
          input.replaceChildren(...res.root.childNodes);
          if (res.selectionAnchor)
            setCursor(res.selectionAnchor, res.anchorOffset);
        } else {
          const res = undo();
          console.log(res, "undo");
          // @ts-ignore
          input.replaceChildren(...res.root.childNodes);
          if (res.selectionAnchor)
            setCursor(res.selectionAnchor, res.anchorOffset);
        }

        e.preventDefault();
        return;
      }

      if (this.listsVisible) {
        if (e.key === "ArrowDown") {
          if (this.selected < this.lists.length - 1) {
            this.setSelected(this.selected + 1);
          }
          e.preventDefault();
        } else if (e.key === "ArrowUp") {
          if (this.selected > 0) {
            this.setSelected(this.selected - 1);
          }
          e.preventDefault();
        } else if (e.key === "Enter") {
          e.preventDefault();
          this.choose(this.lists[this.selected]);
        } else if (e.key === "Escape") {
          e.preventDefault();
          this.listsVisible = false;
        } else if (e.key === " ") {
          let list = this.matchesListExactly();
          if (list != null) {
            this.choose(list);
          } else {
            handleSpace(input);
          }
        }
      } else {
        if (e.key === "Enter") {
          if (e.metaKey) {
            this.handleSave();
          } else {
            commit(input);
          }
        } else if (e.key === " ") {
          handleSpace(input);
        } else if (e.key === "-") {
          handleDash(input);
        } else if (e.key === "Tab") {
          console.log("tabbed");
          handleTab(e, input);
        }
      }
    },

    matchesListExactly() {
      let lists = this.lists;
      let sel = window.getSelection();
      if (sel != null) {
        const node = sel.anchorNode;

        if (node) {
          let text = node.textContent;
          if (text) {
            let match = text.match(TAG_PATTERN);

            if (match != null) {
              let matchingLists = lists.find((l) => {
                return (
                  match &&
                  l.name &&
                  l.name.trim().toLowerCase() === match[1].trim().toLowerCase()
                );
              });

              return matchingLists;
            }
          }
        }
      }

      return null;
    },

    choose(list: List) {
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;
      const focused = document.activeElement === input;

      let sel = window.getSelection();

      if (!focused) {
        // in this case we want to re-add the range
        let r = this.selection;
        if (r && sel) {
          sel.removeAllRanges();
          sel.addRange(r);
        }
      } else {
        console.log("focused");
      }

      if (sel != null) {
        const node = sel.anchorNode;
        if (node) {
          const offset = sel.anchorOffset || 0;
          let textContent = node.textContent || "";

          textContent = textContent.substring(0, offset);

          let range = document.createRange();

          const match = textContent.match(TAG_PATTERN);

          if (match) {
            const start = match.index || 0;

            range.setStart(node, start);
            range.setEndAfter(node);

            range.deleteContents();

            const anchor = document.createElement("a");

            anchor.textContent = "#" + (list.name || "");
            anchor.classList.add("in-text-anchor");
            anchor.href = "/lists/" + list.slug;
            const spacer = document.createTextNode("\xA0");
            range.insertNode(spacer);
            range.insertNode(anchor);

            sel.removeAllRanges();
            range = range.cloneRange();
            range.selectNode(spacer);
            range.collapse(false);
            sel.addRange(range);

            this.listsVisible = false;
            if (list.id) {
              anchor.setAttribute("data-list", list.id);
              this.selectedListNodes.add(list.id);
            }
          }
        }
      }
    },

    isSelected(index: number) {
      return this.selected === index;
    },

    handleFocus() {
      // TODO: check where cursor is and see in the middle of a tag pattern
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;
      if (input.innerText === "start typing...") {
        input.innerHTML = "";
        input.style.color = "black";
        this.disabled = true;
      }
      this.focused = true;
    },

    handleBlur() {
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;
      const sel = window.getSelection();
      if (sel && sel.rangeCount > 0) {
        this.selection = sel.getRangeAt(0);
      }
      if (input.innerHTML === "") {
        this.reset();
      }
      this.focused = false;
    },

    reset() {
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;
      input.innerHTML = "start typing...";
      input.style.color = "#ccc";
      this.disabled = true;
      this.selected = 0;
      this.linkPreview = null;
      this.selectedListNodes = new Set();
      localStorage.removeItem("text-in-progress");
    },

    handlePaste(e: ClipboardEvent) {
      const data = e.clipboardData?.getData("text").trim();
      if (data) {
        const selection = window.getSelection();
        if (selection) {
          if (!selection.rangeCount) return false;

          if (validator.isURL(data)) {
            selection.deleteFromDocument();
            const anchor = document.createElement("a");
            anchor.href = data;
            anchor.textContent = data;
            anchor.classList.add("in-text-anchor");

            selection.getRangeAt(0).insertNode(anchor);
            e.preventDefault();

            this.loadLinkPreview(undefined, {
              target: data,
            });
          }
        }

        // replace with link
      }
    },

    setFilter(f: string) {
      this.filter = f;
      this.setSelected(0);
    },

    handleInput(e: InputEvent) {
      // let input: HTMLDivElement = this.$refs.input as HTMLDivElement;
      // TODO
      // after open
      // if we delete, read the text node and see if there's still
      // a hashtag and no spaces between that and the current selection
      // ALSO, detect a click elsewhere. Close the modal in that case
      // ALSO, close modal on non-focus

      let sel: Selection | null = window.getSelection();
      let anchorNode: Node | null = sel && sel.anchorNode;
      let textContent: string | null = anchorNode && anchorNode.textContent;
      let shouldCommit = false;

      switch (e.inputType) {
        case "insertFromPaste":
          shouldCommit = true;
          break;
        case "insertText":
          if (this.listsVisible) {
            if (textContent && textContent.match(TAG_PATTERN)) {
              const m = textContent.match(TAG_PATTERN);
              if (m) {
                this.setFilter(m[1]);
                shouldCommit = true;
              }
            }
          } else if (e.data === "#") {
            this.listsVisible = true;
          } else if (e.data === " ") {
            shouldCommit = true;
          }
          break;
        case "deleteContentBackward":
          if (sel) {
            if (anchorNode) {
              const parent = anchorNode.parentElement;

              if (
                parent &&
                parent.tagName === "A" &&
                parent.classList.contains("in-text-anchor")
              ) {
                parent.remove();
                let listId = parent.getAttribute("data-list");
                if (listId) {
                  this.selectedListNodes.delete(listId);
                }
              } else if (!textContent || !textContent.match(TAG_PATTERN)) {
                this.listsVisible = false;
                this.filter = "";
              } else {
                const m = textContent.match(TAG_PATTERN);
                if (m) {
                  this.setFilter(m[1]);
                }
              }
            }
          }

          break;
      }

      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;

      if (shouldCommit) {
        commit(input);
      }

      this.disabled = input.innerText.length === 0;
    },
  },

  mounted() {
    const existingText = localStorage.getItem("text-in-progress");
    if (existingText) {
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;
      input.innerHTML = existingText;
      input.style.color = "black";
    }

    this.intervalId = setInterval(() => {
      let input: HTMLDivElement = this.$refs.input as HTMLDivElement;
      if (input.innerHTML !== "start typing...") {
        localStorage.setItem("text-in-progress", input.innerHTML);
      }
    }, 5000);
  },

  beforeUnmount() {
    clearInterval(this.intervalId);
  },

  setup() {
    const { result: linkPreviewResult, load: loadLinkPreview } = useLazyQuery<
      GetLinkPreviewQuery,
      GetLinkPreviewQueryVariables
    >(LINK_PREVIEW_QUERY);

    const linkPreview = ref<LinkPreview | null>(null);

    useResult(linkPreviewResult, {}, (data) => {
      linkPreview.value = data.linkPreview.preview;
    });

    const { mutate, loading: saving } = useMutation<
      CreateLognoteMutation,
      CreateLognoteMutationVariables
    >(CREATE_NOTE_MUTATION, {
      update: (cache, result) => {
        const existingData = cache.readQuery<
          GetNotesQuery,
          GetNotesQueryVariables
        >({
          query: NOTES_QUERY,
          variables: {
            first: 50,
          },
        });

        // TODO: write listITems to cache so they show up whern clicking over
        // to the list

        const update = { ...existingData };

        const lognote = result.data?.createLognote?.lognote;

        if (!update.lognotes) {
          update.lognotes = {
            nodes: [lognote],
            pageInfo: {},
          };
        } else {
          update.lognotes = {
            ...update.lognotes,
            nodes: [lognote],
          };
        }

        cache.writeQuery<GetNotesQuery, GetNotesQueryVariables>({
          query: NOTES_QUERY,
          data: update,
          variables: {
            first: 50,
          },
        });
      },
      optimisticResponse: (vars) => {
        return {
          __typename: "Mutation",
          createLognote: {
            success: true,
            message: "",
            lognote: {
              __typename: "Lognote",
              content: vars.request.note.content,
              createdAt: new Date().getTime().toString(),
              updatedAt: new Date().getTime().toString(),
              attachments: vars.request.attachments.map((d, i) => {
                return {
                  id: `temp-attachment-${i}`,
                  __typename: "LinkAttachment",
                  data: d.data,
                  type: d.type,
                };
              }),
              listItems: {
                pageInfo: {},
                edges: [],
              },
              userId: "any",
              id: `temp-${NOTE_TEMP_ID++}`,
            },
          },
        };
      },
    });

    const listsVisible = ref<boolean>(false);
    const focused = ref<boolean>(false);
    const disabled = ref<boolean>(false);
    const selected = ref<number>(0);
    const selection = ref<Range | null>(null);
    const filter = ref<string>("");
    const selectedListNodes = ref<Set<string>>(new Set());
    const intervalId = ref<number>(0);

    const { lists: allLists, loading: loadingLists } = useLists();

    const lists = computed<List[]>(() => {
      if (filter.value.length === 0) {
        return Array.from(allLists.value);
      }

      const regex = new RegExp(filter.value.split("").join(".*"));

      return allLists.value.filter((l) => {
        return regex.test(l.name.toLowerCase());
      });
    });

    const hasLinkPreview = computed<boolean>(() => {
      return (
        linkPreview.value != null && Object.keys(linkPreview.value).length > 0
      );
    });

    return {
      mutate,
      lists,
      focused,
      loadingLists,
      listsVisible,
      saving,
      Edit,
      filter,
      selection,
      CirclePlus,
      DArrowRight,
      intervalId,
      disabled,
      selected,
      selectedListNodes,
      linkPreview,
      loadLinkPreview,
      hasLinkPreview,
    };
  },
});

const handleDash = (el: HTMLDivElement) => {
  //  const { selection, anchor } = getValidSelection();
  //  const textContent = anchor.textContent?.trim();
  // if (!textContent) {
  //   console.log(
  //     "space detected but no starting character, which is fine. Moving on"
  //   );
  //   return;
  // }
  //  if (textContent.charAt(0) === "-") {
};

const handleSpace = (el: HTMLDivElement) => {
  const { selection, anchor } = getValidSelection();

  const textContent = anchor.textContent?.trim();

  if (!textContent) {
    console.log(
      "space detected but no starting character, which is fine. Moving on"
    );
    return;
  }

  if (textContent.charAt(0) === "#") {
    // detect number of hashes and replace
    let index = 1;
    while (textContent.charAt(index) === "#") {
      index++;
    }
    // only support up to h4
    const header = Math.min(index, 4);

    const headerEl: HTMLHeadingElement = document.createElement(
      `h${header}`
    ) as HTMLHeadingElement;

    let newContent = textContent.replace(/^#+/, "");
    if (newContent.length === 0) {
      newContent = "\u200B";
    }

    const textNode = document.createTextNode(newContent);

    headerEl.appendChild(textNode);

    appendAndMoveCaret(headerEl);
    commit(el);
  } else if (textContent.charAt(0) === "-") {
    let newContent = textContent.replace(/^-/, "");

    // list
    appendList(ListType.UNORDERED, newContent);
    commitNextTick(el);
  } else if (textContent.match(/^\s*1\.?/)) {
    // list
    appendList(ListType.ORDERED);
    commitNextTick(el);
  }

  // find starting character
  // replace with proper element
};

enum ListType {
  UNORDERED = "ul",
  ORDERED = "ol",
}

enum OLTypes {
  NUMBER = "1",
  UPPERCASE_LETTER = "A",
  LOWERCASE_LETTER = "a",
  UPPERCASE_NUMERAL = "I",
  LOWERCASE_NUMERAL = "i",
}
const OL_TYPES = [
  OLTypes.NUMBER,
  OLTypes.UPPERCASE_LETTER,
  OLTypes.LOWERCASE_LETTER,
  OLTypes.UPPERCASE_NUMERAL,
  OLTypes.LOWERCASE_NUMERAL,
];

const appendList = (
  type: ListType = ListType.UNORDERED,
  content: string = "",
  depth: number = 1,
  to?: HTMLElement
): HTMLLIElement => {
  const list = document.createElement(type);
  list.dataset["depth"] = depth.toString();
  if (type === ListType.ORDERED) {
    list.type = OL_TYPES[(depth % OL_TYPES.length) - 1];
  }
  const listItem = document.createElement("li");
  list.appendChild(listItem);

  listItem.append(document.createTextNode(content));

  if (to) {
    to.append(list);
  } else {
    appendAndMoveCaret(list, CaretPosition.START, listItem, to);
  }

  let { selection, anchor } = getValidSelection();
  selection.removeAllRanges();

  // ranges were adding weird non breaking spaces, so we need to set a timeout
  setTimeout(() => {
    let selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    let range = new Range();
    range.setStart(listItem, 0);
    range.setEndAfter(listItem);
    range.collapse(false);
    selection.addRange(range);
  }, 0);

  return listItem;
};

const handleTab = (e: Event, input: HTMLDivElement) => {
  const { selection, anchor } = getValidSelection();

  const parent = anchor.parentElement;
  console.log("anchor", anchor);

  if (
    anchor &&
    anchor.nodeType === Node.ELEMENT_NODE &&
    (anchor as HTMLElement).tagName === "LI"
  ) {
    console.log("anchor is LI");
    appendList(
      parent?.tagName === "UL" ? ListType.UNORDERED : ListType.ORDERED,
      "",
      parseInt(parent?.dataset["depth"] || "0") + 1
    );
    // remove the current anchor
    (anchor as HTMLElement).remove();

    commitNextTick(input);
    // in a list item
  } else if (
    anchor &&
    anchor.nodeType == Node.TEXT_NODE &&
    parent &&
    parent.tagName === "LI"
  ) {
    console.log("anchor is text node");

    // we have text
    // copy the text, append the UL
    const text = anchor.textContent || "";

    const listItem = appendList(
      parent.parentElement?.tagName === "UL"
        ? ListType.UNORDERED
        : ListType.ORDERED,
      text,
      parseInt(parent.parentElement?.dataset["depth"] || "0"),
      parent.parentElement || undefined
    );

    // listItem.appendChild(document.createTextNo de(text));

    // remove the current anchor
    parent.remove();
    commitNextTick(input);
  } else if (
    anchor &&
    anchor.nodeType === Node.ELEMENT_NODE &&
    (anchor as HTMLElement).tagName === "DIV"
  ) {
    console.log("anchor is DIV");

    const el = anchor as HTMLElement;
    el.innerHTML = ""; // remove the line break, if it exists

    const listItem = appendList(ListType.UNORDERED, "", 0, el);
    commitNextTick(input);
  } else {
    console.log("didn't match anchor", anchor);
  }
};

const getValidSelection = () => {
  const selection = window.getSelection();
  if (!selection) {
    throw Error(
      "getValidSelection should be called after a user typed input. There should be a valid selection."
    );
  }

  const anchor = selection.anchorNode;
  if (!anchor) {
    throw Error(
      "getValidSelection should be called after a user typed input. There should be a valid anchor node"
    );
  }

  return { selection, anchor, focus: selection.focusNode };
};

enum CaretPosition {
  START = 0,
  END = 1,
}

const appendAndMoveCaret = (
  el: HTMLElement,
  pos: CaretPosition = CaretPosition.END,
  targetSelection?: Node,
  to?: HTMLElement
) => {
  const { selection, anchor, focus } = getValidSelection();

  let range = selection.getRangeAt(0);
  range.setStart(anchor, 0);

  range.setEndAfter(anchor);

  range.deleteContents();

  range.insertNode(el);
  selection.removeAllRanges();

  const target = targetSelection ?? el.firstChild;

  if (target) {
    range = new Range();
    range.setStart(target, 0);
    range.setEndBefore(target);

    console.log(range.extractContents().firstChild?.textContent);

    selection.addRange(range);
    range.collapse(pos === CaretPosition.START);

    if (pos === CaretPosition.START) {
      selection.collapseToStart();
    } else {
      selection.collapseToEnd();
    }
  }
};

import { gql } from "@apollo/client/core";

const TOGGLE_ITEM_MUTATION = gql`
  mutation toggleItem($id: ID!, $completed: Boolean!) {
    toggleItem(itemId: $id, completed: $completed) {
      success
      message
      listItem {
        id
        snippet
        createdAt
        updatedAt
        todoMetadata {
          completed
          completedAt
          updatedAt
          createdAt
          id
        }
      }
    }
  }
`;

export { TOGGLE_ITEM_MUTATION };

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  RequestHandler,
} from "@apollo/client/core";
import { gql } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        list: {
          keyArgs: ["slug"],
          merge: true,
        },
        lognotes: {
          keyArgs: ["filters"],
          merge: true,
        },
      },
    },
    LognoteQuery: {
      fields: {
        pageInfo: {
          merge: (existing, incoming, { variables }) => {
            // if it's the first page, just load it
            if (!existing) {
              return incoming;
            }

            console.log("resolving paging", variables);
            if (variables && variables.startingAt) {
              return incoming; // start over
            } else if (variables && variables.before) {
              // we're paging backward in time, so refresh prev page
              return {
                ...existing,
                previousCursor: incoming.previousCursor,
                hasPreviousPage: incoming.hasPreviousPage,
              };
            } else if (variables && variables.after) {
              // we're paging forward in time, to take the next cursors
              return {
                ...existing,
                nextCursor: incoming.nextCursor,
                hasNextPage: incoming.hasNextPage,
              };
            }

            // return incoming by default
            return incoming;
          },
        },
        nodes: {
          read(data) {
            return Array.from(data);
          },
          merge(existing = [], incoming, { readField }) {
            const result = [...existing, ...incoming];
            result.sort((a, b) => {
              const startDate = readField<number>("createdAt", a) || 0;
              const startDateB = readField<number>("createdAt", b) || 0;
              return startDate - startDateB;
            });
            return result;
          },
        },
      },
    },
    List: {
      fields: {
        listItemsConnection: {
          keyArgs: false,
          merge: true,
        },
      },
    },
    ListItemConnection: {
      fields: {
        pageInfo: {
          merge: false,
        },
        edges: {
          merge(existing = [], incoming, _args) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

let url = "http://localhost:4000/graphql";

if (process.env.NODE_ENV === "production") {
  url = "https://api.lilonote.com/graphql";
}

// HTTP connection to the API
export const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: url,
});

const typeDefs = gql`
  extend type User {
    dummyData: String
  }

  type Mutation {
    writeUser(user: User!): Boolean
  }
`;

const loadLocalUser = gql`
  query {
    user @client {
      id
      email
    }
  }
`;

const writeLocalUser = gql`
  mutation ($user: User!) {
    writeUser(user: $user) @client
  }
`;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
  // TODO: handle network error and rre-request token if expired
});

export const getClient = (
  link: ApolloLink
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: link.concat(errorLink).concat(httpLink),
    cache,
    typeDefs,
    resolvers: {
      Mutation: {
        writeUser: (_, { user }, { cache }) => {
          const data = cache.readQuery({ query: loadLocalUser });
          data.user = user;
          cache.writeQuery({ query: loadLocalUser, data });
          return true;
        },
      },
    },
  });
};

// // Create the apollo client
// export const apolloClient = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache,
//   typeDefs,
//   resolvers: {
//     Mutation: {
//       writeUser: (_, { user }, { cache }) => {
//         const data = cache.readQuery({ query: loadLocalUser });
//         data.user = user;
//         cache.writeQuery({ query: loadLocalUser, data });
//         return true;
//       },
//     },
//   },
// });

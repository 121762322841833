import { gql } from "graphql-tag";

export const CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    me {
      user {
        email
        id
        apiKey
      }
    }
  }
`;

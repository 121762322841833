import { ListItemFragment } from "./fragments";
import gql from "graphql-tag";

export const LIST_QUERY = gql`
  query GetList($slug: String, $first: Int, $after: String) {
    list(slug: $slug) {
      id
      name
      slug
      userId
      createdAt
      updatedAt
      listItemsConnection(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          nextCursor
        }
        edges {
          node {
            ...ListItemFields
          }
        }
      }
    }
  }
  ${ListItemFragment}
`;

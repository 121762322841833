import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation login($data: LoginInput!) {
    login(data: $data) {
      success
      message
      token
      user {
        email
        id
        apiKey
      }
    }
  }
`;

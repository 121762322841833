import gql from 'graphql-tag';
import { createMutationFunction, createSmartQueryOptionsFunction } from 'vue-apollo-smart-ops';
export const LinkPreviewFieldsFragmentDoc = gql `
    fragment LinkPreviewFields on LinkPreview {
  canonicalURL
  original
  title
  description
  image
}
    `;
export const ListItemFieldsFragmentDoc = gql `
    fragment ListItemFields on ListItem {
  id
  snippet
  createdAt
  updatedAt
  todoMetadata {
    hidden
    id
    completed
    completedAt
    createdAt
    updatedAt
  }
}
    `;
export const CreateListDocument = gql `
    mutation createList($list: ListInput!) {
  createList(list: $list) {
    success
    message
    list {
      id
      createdAt
      updatedAt
    }
  }
}
    `;
/**
 * __createListMutation__
 *
 * To run a mutation, you call `createListMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = createListMutation(this, {
 *   variables: {
 *     list: // value for 'list'
 *   },
 * });
 */
export const createListMutation = createMutationFunction(CreateListDocument);
export const CreateLognoteDocument = gql `
    mutation createLognote($request: LognoteInput!) {
  createLognote(request: $request) {
    success
    message
    lognote {
      id
      userId
      content
      createdAt
      updatedAt
      attachments {
        ... on LinkAttachment {
          id
          data
          type
          metadata {
            ...LinkPreviewFields
          }
        }
      }
      listItems {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            snippet
            list {
              id
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
}
    ${LinkPreviewFieldsFragmentDoc}`;
/**
 * __createLognoteMutation__
 *
 * To run a mutation, you call `createLognoteMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = createLognoteMutation(this, {
 *   variables: {
 *     request: // value for 'request'
 *   },
 * });
 */
export const createLognoteMutation = createMutationFunction(CreateLognoteDocument);
export const GetCurrentUserDocument = gql `
    query GetCurrentUser {
  me {
    user {
      email
      id
      apiKey
    }
  }
}
    `;
/**
 * __useGetCurrentUserQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetCurrentUserQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getCurrentUser: useGetCurrentUserQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetCurrentUserQuery = createSmartQueryOptionsFunction(GetCurrentUserDocument);
export const GetListsDocument = gql `
    query GetLists {
  lists {
    id
    name
    userId
    createdAt
    updatedAt
    slug
  }
}
    `;
/**
 * __useGetListsQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetListsQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getLists: useGetListsQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetListsQuery = createSmartQueryOptionsFunction(GetListsDocument);
export const GetNotesDocument = gql `
    query GetNotes($first: Int, $after: String, $last: Int, $before: String, $filters: LognoteQueryFilters) {
  lognotes(
    first: $first
    after: $after
    last: $last
    before: $before
    filters: $filters
  ) {
    earliestDate
    pageInfo {
      hasNextPage
      nextCursor
      previousCursor
      hasPreviousPage
    }
    nodes {
      id
      content
      userId
      createdAt
      attachments {
        ... on LinkAttachment {
          id
          data
          type
          metadata {
            ...LinkPreviewFields
          }
        }
      }
    }
  }
}
    ${LinkPreviewFieldsFragmentDoc}`;
/**
 * __useGetNotesQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetNotesQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getNotes: useGetNotesQuery({
 *       variables: {
 *         first: // value for 'first'
 *         after: // value for 'after'
 *         last: // value for 'last'
 *         before: // value for 'before'
 *         filters: // value for 'filters'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetNotesQuery = createSmartQueryOptionsFunction(GetNotesDocument);
export const GetTimelineDocument = gql `
    query GetTimeline($params: TimelineQueryFilters!) {
  timeline(params: $params) {
    timeline {
      points {
        count
        date
      }
    }
  }
}
    `;
/**
 * __useGetTimelineQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetTimelineQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getTimeline: useGetTimelineQuery({
 *       variables: {
 *         params: // value for 'params'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetTimelineQuery = createSmartQueryOptionsFunction(GetTimelineDocument);
export const GetUsersDocument = gql `
    query GetUsers {
  users {
    email
  }
}
    `;
/**
 * __useGetUsersQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetUsersQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getUsers: useGetUsersQuery({
 *       variables: {},
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetUsersQuery = createSmartQueryOptionsFunction(GetUsersDocument);
export const GetLinkPreviewDocument = gql `
    query GetLinkPreview($target: String!) {
  linkPreview(target: $target) {
    preview {
      ...LinkPreviewFields
    }
  }
}
    ${LinkPreviewFieldsFragmentDoc}`;
/**
 * __useGetLinkPreviewQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetLinkPreviewQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getLinkPreview: useGetLinkPreviewQuery({
 *       variables: {
 *         target: // value for 'target'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetLinkPreviewQuery = createSmartQueryOptionsFunction(GetLinkPreviewDocument);
export const GetListDocument = gql `
    query GetList($slug: String, $first: Int, $after: String) {
  list(slug: $slug) {
    id
    name
    slug
    userId
    createdAt
    updatedAt
    listItemsConnection(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        nextCursor
      }
      edges {
        node {
          ...ListItemFields
        }
      }
    }
  }
}
    ${ListItemFieldsFragmentDoc}`;
/**
 * __useGetListQuery__
 *
 * To use a Smart Query within a Vue component, call `useGetListQuery` as the value for a query key
 * in the component's `apollo` config, passing any options required for the query.
 *
 * @param options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
 *
 * @example
 * {
 *   apollo: {
 *     getList: useGetListQuery({
 *       variables: {
 *         slug: // value for 'slug'
 *         first: // value for 'first'
 *         after: // value for 'after'
 *       },
 *       loadingKey: 'loading',
 *       fetchPolicy: 'no-cache',
 *     }),
 *   }
 * }
 */
export const useGetListQuery = createSmartQueryOptionsFunction(GetListDocument);
export const LoginDocument = gql `
    mutation login($data: LoginInput!) {
  login(data: $data) {
    success
    message
    token
    user {
      email
      id
      apiKey
    }
  }
}
    `;
/**
 * __loginMutation__
 *
 * To run a mutation, you call `loginMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = loginMutation(this, {
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export const loginMutation = createMutationFunction(LoginDocument);
export const ToggleItemDocument = gql `
    mutation toggleItem($id: ID!, $completed: Boolean!) {
  toggleItem(itemId: $id, completed: $completed) {
    success
    message
    listItem {
      id
      snippet
      createdAt
      updatedAt
      todoMetadata {
        completed
        completedAt
        updatedAt
        createdAt
        id
      }
    }
  }
}
    `;
/**
 * __toggleItemMutation__
 *
 * To run a mutation, you call `toggleItemMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = toggleItemMutation(this, {
 *   variables: {
 *     id: // value for 'id'
 *     completed: // value for 'completed'
 *   },
 * });
 */
export const toggleItemMutation = createMutationFunction(ToggleItemDocument);

import { LISTS_QUERY } from "@lognote/common";
import { GetListsQuery } from "@lognote/common/src/types/generated/types";
import { useQuery, useResult } from "@vue/apollo-composable";
import { ref } from "vue";

export const useLists = () => {
  const { result, loading, error } = useQuery<GetListsQuery>(LISTS_QUERY);
  const lists = useResult(result, [], (data) => data.lists);

  return { lists, loading, error };
};

import { isToday, format, isThisWeek, isSameDay } from "date-fns";

export const formatDate = (time: number) => {
  if (!isNaN(time)) {
    if (isToday(time)) {
      return format(time, "h:mm aaa");
    } else if (isThisWeek(time)) {
      return format(time, "eeee 'at' h:mm aaa");
    } else {
      return format(time, "MMM do h:mm aaa");
    }
  }
  return "not sure";
};

export const formatDateWithoutTime = (time: number) => {
  if (!isNaN(time)) {
    if (isToday(time)) {
      return "today";
    } else if (isThisWeek(time)) {
      return format(time, "eeee");
    } else {
      return format(time, "MMM do");
    }
  }
  return "not sure";
};


import { UserKey } from "../lib/util/injectionKeys";
import Sidenav from "./Sidenav.vue";
import { ArrowDown, ColdDrink } from "@element-plus/icons-vue";
import {
  User,
  CURRENT_USER_QUERY,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
} from "@lognote/common";
import { useQuery, useResult } from "@vue/apollo-composable";
import { provide, ref, InjectionKey, Ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Main",
  components: {
    Sidenav,
    ArrowDown,
  },

  setup() {
    const userRef = ref<User | null>(null);

    const loadingUser = ref(false);

    const r = useRouter();

    const userDataJson = localStorage.getItem("loginResult");
    if (!userDataJson) {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("User not logged in");
      } else {
        loadingUser.value = true;
        console.log("attempting to load user");
        const { result, loading, fetchMore, onResult } = useQuery<
          GetCurrentUserQuery,
          GetCurrentUserQueryVariables
        >(CURRENT_USER_QUERY);

        onResult((result) => {
          localStorage.setItem(
            "loginResult",
            JSON.stringify(result.data.me?.user)
          );

          r.push("/notebook");
        });

        const user = useResult(result, {}, (r) => {
          return r.me.user;
        });
        userRef.value = user.value;
      }
    } else {
      const userData: User = JSON.parse(userDataJson);
      userRef.value = userData;
    }

    provide(UserKey, userRef);

    const logout = () => {
      localStorage.removeItem("loginResult");
      localStorage.removeItem("token");
      // @ts-ignore doesn't know that gapi is on the window
      const authInstance = window.gapi.auth2.getAuthInstance();

      if (authInstance) {
        authInstance.signOut();
      }

      r.push("/login");
    };

    return { userRef, Sidenav, ArrowDown, ColdDrink, logout, loadingUser };
  },
};

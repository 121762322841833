import Authorize from "./components/Authorize.vue";
import Home from "./components/Home.vue";
import List from "./components/List.vue";
import ListItem from "./components/ListItem.vue";
import Login from "./components/Login.vue";
import Main from "./components/Main.vue";
import Notebook from "./components/Notebook.vue";
import { setContext } from "@apollo/client/link/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faList,
  faPlus,
  faHashtag,
  faAlignJustify,
  faBook,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { getClient } from "@lognote/common";
import { DefaultApolloClient } from "@vue/apollo-composable";
import ElementPlus from "element-plus";
import isElectron from "is-electron";
import { createApp, provide, h } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// electron
if (isElectron()) {
  console.log("loading electron env");
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || "",
    },
  };
});

// @ts-ignore
const apolloClient = getClient(authLink);

// icons
library.add(faList, faPlus, faHashtag, faBook, faRocket);

require("element-plus/dist/index.css");
require("./style/theme.css");

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: Main,
    children: [
      // @ts-ignore <- ignore it
      { path: "/notebook", component: Notebook },
      {
        path: "/lists/:slug",
        // @ts-ignore <- ignore it
        component: List,
        props: true,
      },
    ],
  },
  { path: "/login", component: Login },
  { path: "/authorize", component: Authorize },
];
// @ts-ignore <- ignore it
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(Home),
});

app.use(router);
app.use(ElementPlus);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");

declare global {
  interface WindowEventMap {
    setUserToken: CustomEvent<{ token: string }>;
  }
}

window.addEventListener("setUserToken", (e: CustomEvent<{ token: string }>) => {
  console.log("token set", e.detail);
  localStorage.setItem("token", e.detail.token);
  router.push("/notebook");
});

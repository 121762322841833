
import { Check, CircleCheck } from "@element-plus/icons-vue";
import {
  List,
  ListItemFieldsFragment,
  ToggleItemMutation,
  ToggleItemMutationVariables,
  TOGGLE_ITEM_MUTATION,
} from "@lognote/common";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import Showdown from "showdown";
import { computed, ref } from "vue";

type Props = {
  item: ListItemFieldsFragment;
  list: List;
};
export default {
  name: "ListItem",
  components: {
    Check,
  },
  props: {
    item: {
      type: Object as () => ListItemFieldsFragment,
      required: true,
    },
    list: {
      type: Object as () => List,
      required: true,
    },
  },

  // TODO: properly type this
  setup(props: Props) {
    const { mutate: toggleItem } = useMutation<
      ToggleItemMutation,
      ToggleItemMutationVariables
    >(TOGGLE_ITEM_MUTATION, {
      update: (cache, queryResult) => {},
      optimisticResponse: (vars) => {
        const todoId = props.item.todoMetadata?.id || "test";
        return {
          __typename: "Mutation",
          toggleItem: {
            message: "",
            success: true,
            listItem: {
              ...props.item,
              todoMetadata: {
                ...props.item.todoMetadata,
                id: todoId,
                completed: vars.completed,
                completedAt: new Date().getTime().toString(),
              },
            },
          },
        };
      },
    });

    const handleChange = (e: InputEvent) => {
      toggleItem({
        id: props.item.id,
        completed: !props.item.todoMetadata?.completed,
      });
    };

    const completed = computed(() => {
      return props.item.todoMetadata && props.item.todoMetadata.completed;
    });

    const converter = new Showdown.Converter();
    const getHtml = (snippet: string) => {
      return converter.makeHtml(preprocess(snippet, props.list));
    };

    return { getHtml, handleChange, toggleItem, completed };
  },
};

function preprocess(snippet: string, list: List) {
  // remove matching list name
  let name = list.name;
  if (name) {
    snippet = snippet.replace(`#${name}`, "");
  }

  // remove leading bullets, note this could also be done before saving
  snippet = snippet.replace(/^[-\s]*/, "");

  return snippet;
}

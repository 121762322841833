import { gql } from "@apollo/client/core";

const USER_QUERY = gql`
  query GetUsers {
    users {
      email
    }
  }
`;

export { USER_QUERY };

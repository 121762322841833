import { gql } from "@apollo/client/core";

export const CREATE_LIST_MUTATION = gql`
  mutation createList($list: ListInput!) {
    createList(list: $list) {
      success
      message
      list {
        id
        createdAt
        updatedAt
      }
    }
  }
`;

export type CreateListResult = {
  createList: {
    success: boolean;
    message: string;
    list: {
      id: number;
      name: string;
      userId: number;
      createdAt: string;
      updatedAt: string;
    };
  };
};

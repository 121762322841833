
import { formatDate } from "../lib/util/date";
import { convert, parse } from "../lib/util/editor";
import LinkPreview from "./LinkPreview.vue";
import {
  CreateLognoteMutation,
  CreateLognoteMutationVariables,
  CREATE_NOTE_MUTATION,
  EditorNode,
  GetNotesQuery,
  GetNotesQueryVariables,
  NOTES_QUERY,
} from "@lognote/common";
import { useMutation } from "@vue/apollo-composable";
import { isToday, format, isThisWeek, isSameDay } from "date-fns";
import Showdown from "showdown";
import { ref } from "vue";

export default {
  props: {
    note: {
      type: Object,
    },
    prev: {
      type: Object,
    },
  },
  name: "NoteItem",
  components: { LinkPreview },

  methods: {
    newDay(note: any, prev: any) {
      if (prev == null) {
        return true;
      }

      return !isSameDay(parseInt(note.createdAt), parseInt(prev.createdAt));
    },
    renderNote(note: any) {
      let result: string | null = null;
      try {
        result = parse(note.content).root.innerHTML;
      } catch (e) {
        const s = new Showdown.Converter();

        let div = document.createElement("div");
        div.innerHTML = s.makeHtml(note.content);
        console.log("original", div);
        let convertedData = convert(div);
        console.log("converted data", convertedData);
        result = parse(convertedData.serialize()).root.innerHTML;
        console.log("result", result);

        // @ts-ignore
        if (!this.updatedNote) {
          // @ts-ignore
          this.updatedNote = true;
          console.log(
            "dry run updating",
            convertedData.serialize(),
            convertedData.getText()
          );
          // @ts-ignore
          // this.mutate({
          //   request: {
          //     note: { content: convertedData.serialize(), id: note.id },
          //     listItems: [],
          //     attachments: [],
          //   },
          // });
        }
      }

      return result;
    },

    formatTime(timeStr: string) {
      return formatDate(parseInt(timeStr));
    },

    formatDate(timeStr: string) {
      const time = parseInt(timeStr);
      return format(time, "MMMM do");
    },
  },

  setup() {
    const updatedNote = ref(false);
    const { mutate, loading: saving } = useMutation<
      CreateLognoteMutation,
      CreateLognoteMutationVariables
    >(CREATE_NOTE_MUTATION);

    return { mutate, updatedNote };
  },
};

import { gql } from "@apollo/client/core";

export const LISTS_QUERY = gql`
  query GetLists {
    lists {
      id
      name
      userId
      createdAt
      updatedAt
      slug
    }
  }
`;

export type ListQueryResult = {
  lists: {
    id: number;
    name: string;
    userId: number;
    slug: string;
    createdAt: string;
    updatedAt: string;
  }[];
};

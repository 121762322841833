
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  computed: {
    username() {
      // We will see what `params` is shortly
      return this.$route.params.username;
    },
  },
  methods: {
    goToDashboard() {},
  },
  mounted() {
    const result = localStorage.getItem("token");
    if (!result) {
      this.$router.push("/login");
    } else if (this.$route.name === "/") {
      this.$router.push("/notebook");
    }
  },
});

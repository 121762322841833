export var EditorNodeType;
(function (EditorNodeType) {
    EditorNodeType["PARAGRAPH"] = "p";
    EditorNodeType["TEXT"] = "text";
    EditorNodeType["TEXT_CONTAINER"] = "tc";
    EditorNodeType["UNORDERED_LIST"] = "ul";
    EditorNodeType["ORDERED_LIST"] = "ol";
    EditorNodeType["LIST_ITEM"] = "li";
    EditorNodeType["ANCHOR"] = "a";
    EditorNodeType["CONTAINER"] = "div";
    EditorNodeType["BREAK"] = "br";
})(EditorNodeType || (EditorNodeType = {}));
export class EditorNode {
    constructor(type) {
        this.content = null;
        this.decorators = [];
        this.children = [];
        this.selectionAnchor = false;
        this.anchorOffset = 0;
        this.type = type;
    }
    static deserialize(json) {
        const editorNodeJSON = JSON.parse(json);
        const root = new EditorNode(editorNodeJSON.t);
        root.fromJSONNode(editorNodeJSON);
        return root;
    }
    fromJSONNode(json) {
        this.content = json.c;
        this.decorators = json.d;
        this.children = json.ch.map((c) => {
            const node = new EditorNode(c.t);
            node.fromJSONNode(c);
            return node;
        });
    }
    addChild(node) {
        this.children.push(node);
    }
    getText(curr) {
        if (!curr) {
            curr = "";
        }
        if (this.content) {
            curr += " " + this.content;
        }
        if (this.children.length > 0) {
            for (let i = 0; i < this.children.length; i++) {
                curr = this.children[i].getText(curr);
            }
        }
        return curr;
    }
    withContent(content) {
        this.content = content;
        return this;
    }
    withDecorator(decorator) {
        this.decorators.push(decorator);
        return this;
    }
    serialize() {
        const serializedRoot = new EditorNodeJSON(this);
        return JSON.stringify(serializedRoot);
    }
}
export class EditorNodeJSON {
    constructor(root) {
        this.c = null;
        this.d = [];
        this.ch = [];
        this.c = root.content;
        this.d = root.decorators;
        this.t = root.type;
        this.ch = root.children.map((c) => {
            return new EditorNodeJSON(c);
        });
    }
}
export var Decorator;
(function (Decorator) {
    Decorator["BOLD"] = "b";
    Decorator["ITALIC"] = "i";
})(Decorator || (Decorator = {}));

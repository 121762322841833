
import DateControl from "./DateControl.vue";
import SearchControl from "./SearchControl.vue";
import { Clock, Search } from "@element-plus/icons-vue";
import { LognoteQueryFilters } from "@lognote/common";
import { ref } from "vue";

enum ContentType {
  TIMELINE = "timeline",
  SEARCH = "search",
}

type Props = {
  filters: LognoteQueryFilters;
};

type Context = {
  emit: (e: "update", arg: LognoteQueryFilters) => void;
};

export default {
  components: {
    DateControl,
    SearchControl,
    Search,
    Clock,
  },
  props: {
    filters: {
      type: Object as () => LognoteQueryFilters,
      required: true,
    },
  },
  emits: {
    update: (arg: LognoteQueryFilters) => {
      return true;
    },
  },
  name: "NotebookControls",
  setup(props: Props, { emit }: Context) {
    const drawer = ref(false);

    const content = ref<ContentType | null>(null);

    const shouldShowTimeline = () => {
      return content.value == ContentType.TIMELINE;
    };

    const handleDateChange = (arg: Date) => {
      emit("update", {
        ...props.filters,
        startingAt: arg.getTime().toString(),
      });
    };

    const selected = (type: string) => {
      if (content.value === null) {
        return false;
      }

      return content.value === type;
    };

    const iconColor = (type: string) => {
      if (content.value === null || !drawer.value) {
        return "#ccc";
      }

      return content.value === type
        ? getComputedStyle(document.documentElement).getPropertyValue(
            "--el-color-primary"
          )
        : "#ccc";
    };
    const toggle = (type: ContentType) => {
      if (content.value === type) {
        drawer.value = !drawer.value;
      } else {
        content.value = type;
        drawer.value = true;
      }
    };

    const handleSearchChange = (startingAt: string) => {
      emit("update", {
        ...props.filters,
        startingAt,
      });
    };

    return {
      drawer,
      content,
      toggle,
      shouldShowTimeline,
      handleDateChange,
      handleSearchChange,
      iconColor,
      Clock,
      Search,
      selected,
      startingAt: props.filters.startingAt,
    };
  },
};

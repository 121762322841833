import gql from "graphql-tag";

export const LinkPreviewFieldsFragment = gql`
  fragment LinkPreviewFields on LinkPreview {
    canonicalURL
    original
    title
    description
    image
  }
`;


import ListItem from "./ListItem.vue";
import { GetListQuery, GetListQueryVariables } from "@lognote/common";
import { LIST_QUERY } from "@lognote/common/lib/graphql";
import { useQuery, useResult } from "@vue/apollo-composable";
import { watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "List",
  components: { ListItem },

  setup() {
    // expose to template
    const route = useRoute();
    const slug = route.params.slug as string;

    const { result, refetch, loading, fetchMore } = useQuery<
      GetListQuery,
      GetListQueryVariables
    >(LIST_QUERY, {
      slug,
      first: 25,
    });

    const list = useResult(result, {}, (data) => {
      return data.list;
    });

    const hasNextPage = useResult(result, {}, (data) => {
      return data.list.listItemsConnection.pageInfo.hasNextPage;
    });

    const nextPageToken = useResult(result, {}, (data) => {
      return data.list.listItemsConnection.pageInfo.nextCursor;
    });

    const items = useResult(result, [], (data) => {
      return data.list.listItemsConnection.edges;
    });

    watch(
      () => route.params,
      (toParams, previousParams) => {
        refetch({ slug: toParams.slug as string });
      }
    );

    const handleLoadMore = () => {
      fetchMore({
        variables: {
          slug,
          first: 25,
          after: nextPageToken.value as string,
        },
      });
    };

    return { list, items, loading, hasNextPage, handleLoadMore };
  },
};

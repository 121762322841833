import { gql } from "graphql-tag";
export const TIMELINE_QUERY = gql `
  query GetTimeline($params: TimelineQueryFilters!) {
    timeline(params: $params) {
      timeline {
        points {
          count
          date
        }
      }
    }
  }
`;


import { formatDateWithoutTime } from "../lib/util/date";
import {
  GetTimelineQuery,
  GetTimelineQueryVariables,
  TimelinePoint,
} from "@lognote/common";
import { TIMELINE_QUERY } from "@lognote/common/lib/graphql/GetTimeline";
import { useQuery, useResult } from "@vue/apollo-composable";
import { isToday } from "date-fns";
import { ref, onMounted, watch, nextTick, computed, onUnmounted } from "vue";

type Props = {};

export default {
  name: "Timeline",
  components: {},

  props: {},
  emits: {
    timelineSelect: (date: number) => {
      return true;
    },
  },

  setup(
    props: Props,
    { emit }: { emit: (e: "timelineSelect", arg: number) => void }
  ) {
    const { result, error, loading, fetchMore, refetch } = useQuery<
      GetTimelineQuery,
      GetTimelineQueryVariables
    >(TIMELINE_QUERY, { params: {} });

    const timelineItems = useResult(result, [], (res) => {
      let timelineItems: TimelinePoint[] = [];

      let prev: null | TimelinePoint = null;
      for (let i = 0; i < res.timeline.timeline.points.length; i++) {
        let point = res.timeline.timeline.points[i];
        console.log("point", point);
        if (prev) {
          let prevDate = parseInt(prev.date || "0");
          if (prevDate == 0 || isNaN(prevDate)) {
            console.error("incorrect data returned");
            return;
          }
          if (parseInt(point.date) + 1000 * 60 * 60 * 24 < prevDate) {
            let currentDate = parseInt(point.date);
            while (currentDate + 1000 * 60 * 60 * 24 < prevDate) {
              currentDate += 1000 * 60 * 60 * 24;
              timelineItems.push({
                date: currentDate.toString(),
                count: 0,
                __typename: "TimelinePoint",
              });
            }
          }
          timelineItems.push(point);
        } else {
          timelineItems.push(point);
        }

        prev = point;
      }

      return timelineItems;
    });

    const handleSelect = (val: number) => {
      emit("timelineSelect", val);
    };

    const maxCount = computed(() => {
      let max = 0;
      if (timelineItems.value) {
        for (let i = 0; i < timelineItems.value.length; i++) {
          let point: TimelinePoint = timelineItems.value[i];
          max = Math.max(max, point.count || 0);
        }
      }

      return max;
    });

    // expose to template
    return {
      loading,
      maxCount,
      error,
      isToday,
      handleSelect,
      timelineItems,
      formatDateWithoutTime,
    };
  },
};

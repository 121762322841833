import { LinkPreviewFieldsFragment } from "./fragments/LinkPreviewFieldsFragment";
import { gql } from "graphql-tag";

export const NOTES_QUERY = gql`
  query GetNotes(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filters: LognoteQueryFilters
  ) {
    lognotes(
      first: $first
      after: $after
      last: $last
      before: $before
      filters: $filters
    ) {
      earliestDate
      pageInfo {
        hasNextPage
        nextCursor
        previousCursor
        hasPreviousPage
      }
      nodes {
        id
        content
        userId
        createdAt
        attachments {
          ... on LinkAttachment {
            id
            data
            type
            metadata {
              ...LinkPreviewFields
            }
          }
        }
      }
    }
  }

  ${LinkPreviewFieldsFragment}
`;

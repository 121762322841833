
import { ref } from "vue";

export default {
  name: "DateControl",
  props: {
    earliestDate: {
      type: Number,
    },
  },
  emits: {
    change: (date: Date) => {
      return true;
    },
  },
  setup(props: any, { emit }: { emit: (e: "change", arg: Date) => void }) {
    const value = ref("");

    // const formatTooltip = (val: number) => {
    //   if (val === 0) {
    //     return "Right now";
    //   }

    //   return `${formatDate(Date.now() - val)}`;
    // };

    // const handleChange = (arg: number) => {
    //   emit("change", Date.now() - arg);
    // };

    // const getHeight = () => {
    //   if (sliderParent.value == null) {
    //     return "400px";
    //   }
    //   return sliderParent.value.getBoundingClientRect().height + "px";
    // };

    const shortcuts = [
      {
        text: "Today",
        value: new Date(),
      },
      {
        text: "Yesterday",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: "A week ago",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];

    const handleChange = (val: Date) => {
      emit("change", val);
    };

    return {
      shortcuts,
      value,
      handleChange,
    };
  },
};


import NoteEventTarget, { Events } from "../lib/util/NoteEventTarget";
import NoteItem from "./NoteItem.vue";
import Timeline from "./Timeline.vue";
import { Plus, Magnet } from "@element-plus/icons-vue";
import {
  GetNotesQuery,
  GetNotesQueryVariables,
  LognoteQueryFilters,
} from "@lognote/common";
import { NOTES_QUERY } from "@lognote/common/lib/graphql/GetNotes";
import { useQuery, useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { ref, onMounted, watch, nextTick, computed, onUnmounted } from "vue";

type Props = {
  filters: LognoteQueryFilters;
};

export default {
  name: "NoteList",
  components: { NoteItem, Timeline },

  props: {
    filters: {
      required: true,
      type: Object as () => LognoteQueryFilters,
    },
  },

  emits: {
    updateDate: (date: number) => {
      return true;
    },
  },

  setup(
    props: Props,
    { emit }: { emit: (e: "updateDate", arg: number) => void }
  ) {
    const { result, error, loading, fetchMore, refetch } = useQuery<
      GetNotesQuery,
      GetNotesQueryVariables
    >(NOTES_QUERY, {
      first: 50,
    });

    watch(
      () => props.filters.startingAt,
      (incoming, previous) => {
        return refetch({
          first: 50,
          filters: {
            startingAt: incoming,
          },
        });
      }
    );

    const notes = useResult(result, [], (data) => {
      return data.lognotes.nodes;
    });

    const hasNextPage = useResult(result, [], (data) => {
      return data.lognotes.pageInfo.hasNextPage;
    });

    const nextPageToken = useResult(result, [], (data) => {
      return data.lognotes.pageInfo.nextCursor;
    });

    const hasPrevPage = useResult(result, [], (data) => {
      return data.lognotes.pageInfo.hasPreviousPage;
    });

    const prevPageToken = useResult(result, [], (data) => {
      return data.lognotes.pageInfo.previousCursor;
    });

    const earliestDate = useResult(result, [], (data) => {
      return parseInt(data.lognotes.earliestDate);
    });

    const content = ref<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
      console.log("scroll to bottom called", content);
      const contentEl = content.value;
      if (contentEl != null) {
        console.log("scroll to bottom called el", contentEl);

        contentEl.scrollTo({
          top: contentEl.scrollHeight,
          behavior: "smooth",
        });
      }
    };

    const onNoteCreate = (e: Event) => {
      console.log("note created", e);
      scrollToBottom();
    };

    NoteEventTarget.addEventListener(Events.NOTE_CREATE, onNoteCreate);

    onUnmounted(() => {
      NoteEventTarget.removeEventListener(Events.NOTE_CREATE, onNoteCreate);
    });

    const scrollToEl = (el: HTMLElement | null) => {
      if (el == null) {
        return;
      }
      console.log("scrolling to el", el);
      const contentEl = content.value;
      if (contentEl != null) {
        contentEl.scrollTo({
          top: el.offsetTop - 210,
        });
      }
    };

    onMounted(() => {
      scrollToBottom();
    });

    watch(notes, (incoming, previous) => {
      if (previous.length == 0) {
        nextTick(() => {
          scrollToBottom();
        });
      } else if (incoming.length == 0) {
        nextTick(() => {
          scrollToBottom();
        });
      } else {
        const firstItem = previous[0];
        const lastEl = document.getElementById("note-item-" + firstItem.id);

        const newFirstItem = incoming[0];

        if (firstItem.id === newFirstItem.id) {
          // if the new first item is the same, we paged
          // if it's new, we added
          return;
        }

        nextTick(() => {
          scrollToEl(lastEl);
        });
        // scroll to previous top note
      }
    });

    const handleLoadPrev = () => {
      fetchMore({
        variables: {
          first: 50,
          before: prevPageToken.value as string,
        },
      });
    };

    const handleLoadNext = () => {
      fetchMore({
        variables: {
          first: 50,
          after: nextPageToken.value as string,
        },
      });
    };

    // const handleDateChange = (date: number) => {
    //   refetch({
    //     first: 50,
    //     filters: {
    //       startingAt: date.toString(),
    //     },
    //   });
    //   console.log("date changed", date);
    // };

    // expose to template

    const handleTimelineSelect = (val: number) => {
      emit("updateDate", val);
    };

    return {
      loading,
      content,
      error,
      handleLoadPrev,
      handleLoadNext,
      notes,
      earliestDate,
      hasNextPage,
      hasPrevPage,
      handleTimelineSelect,
      scrollToBottom,
      // handleDateChange,
      Plus,
      Magnet,
    };
  },
};

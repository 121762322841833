
import {
  LoginMutation,
  LoginMutationVariables,
  LOGIN_MUTATION,
} from "@lognote/common";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import isElectron from "is-electron";
import { defineComponent } from "vue";

type GoogleLoginResponse = {
  getAuthResponse: () => { id_token: string };
};

export default defineComponent({
  name: "Login",
  components: {},
  methods: {
    async onSignIn(res: GoogleLoginResponse) {
      const idToken = res.getAuthResponse().id_token;
      const loginResult = await this.login({ data: { token: idToken } });
      if (loginResult && loginResult.data && loginResult.data.login) {
        localStorage.setItem(
          "loginResult",
          JSON.stringify(loginResult.data.login.user)
        );
        localStorage.setItem("token", loginResult.data.login.token || "");
        this.$router.push("/notebook");
      } else {
        throw new Error("Login failed!");
      }
    },
  },

  setup() {
    const { mutate: login } = useMutation<
      LoginMutation,
      LoginMutationVariables
    >(LOGIN_MUTATION);

    const visitWebSignIn = () => {
      if (!isElectron()) {
        console.error("This should only be used by the desktop app");
        return;
      }
      if (process.env.NODE_ENV === "production") {
        window.open("https://app.lilonote.com/authorize");
      } else {
        window.open("http://localhost:8081/authorize");
      }
    };

    // expose to template
    return {
      login,
      isElectron: isElectron,
      visitWebSignIn,
    };
  },

  mounted() {
    let token = localStorage.getItem("token");
    // @ts-ignore
    if (!isElectron() && window && window.gapi) {
      // @ts-ignore
      window.gapi.signin2.render("google-signin-button", {
        onsuccess: this.onSignIn,
        onfailure: this.onSignIn,
        width: 240,
        theme: "dark",
        height: 50,
        longtitle: true,
      });
    } else if (token) {
      this.$router.push("/notebook");
    }
  },
});

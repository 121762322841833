type GoogleLoginResponse = {
  getAuthResponse: () => { id_token: string };
};

export const logout = () => {
  localStorage.removeItem("loginResult");
  // @ts-ignore
  window.gapi.auth2.getAuthInstance().signOut();
  window.location.href = "/";
};

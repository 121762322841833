import { EditorNode, EditorNodeType, Decorator, } from "@lognote/common";
export const convert = (html) => {
    const root = new EditorNode(EditorNodeType.CONTAINER);
    const selection = window.getSelection();
    buildJSTree(html, root, selection);
    return root;
};
function buildJSTree(html, parent, selection) {
    let newNode = null;
    if (html.nodeType === Node.TEXT_NODE) {
        newNode = new EditorNode(EditorNodeType.TEXT).withContent(html.textContent);
    }
    else if (html.nodeType === Node.ELEMENT_NODE) {
        const el = html;
        switch (el.tagName) {
            case "P":
                newNode = new EditorNode(EditorNodeType.PARAGRAPH);
                break;
            case "DIV":
                newNode = new EditorNode(EditorNodeType.CONTAINER);
                break;
            case "OL":
                newNode = new EditorNode(EditorNodeType.ORDERED_LIST);
                break;
            case "UL":
                newNode = new EditorNode(EditorNodeType.UNORDERED_LIST);
                break;
            case "B":
                newNode = new EditorNode(EditorNodeType.TEXT_CONTAINER).withDecorator(Decorator.BOLD);
                break;
            case "SPAN":
                newNode = new EditorNode(EditorNodeType.TEXT_CONTAINER);
                break;
            case "A":
                newNode = new EditorNode(EditorNodeType.ANCHOR);
                break;
            case "LI":
                newNode = new EditorNode(EditorNodeType.LIST_ITEM);
                break;
            case "BR":
                newNode = new EditorNode(EditorNodeType.BREAK);
                break;
            case "I":
                newNode = new EditorNode(EditorNodeType.TEXT_CONTAINER).withDecorator(Decorator.ITALIC);
                break;
        }
    }
    if (selection && html == selection.anchorNode && newNode) {
        newNode.selectionAnchor = true;
        newNode.anchorOffset = selection.anchorOffset;
        console.log("found selection");
    }
    if (newNode != null) {
        parent.addChild(newNode);
        if (html.childNodes.length > 0) {
            for (let i = 0; i < html.childNodes.length; i++) {
                const child = html.childNodes[i];
                buildJSTree(child, newNode, selection);
            }
        }
    }
    else {
        console.error("unknown el detected", html);
    }
}
export const parse = (json) => {
    return buildHTMLTree(EditorNode.deserialize(json), null);
};
function buildHTMLTree(node, parent) {
    let child = null;
    let root = null;
    let selectionAnchor = null;
    let anchorOffset = 0;
    switch (node.type) {
        case EditorNodeType.ANCHOR:
            child = document.createElement("a");
            break;
        case EditorNodeType.PARAGRAPH:
            child = document.createElement("p");
            break;
        case EditorNodeType.CONTAINER:
            child = document.createElement("div");
            break;
        case EditorNodeType.BREAK:
            child = document.createElement("br");
            break;
        case EditorNodeType.LIST_ITEM:
            child = document.createElement("li");
            break;
        case EditorNodeType.ORDERED_LIST:
            child = document.createElement("or");
            break;
        case EditorNodeType.UNORDERED_LIST:
            child = document.createElement("ul");
            break;
        case EditorNodeType.TEXT_CONTAINER:
            child = document.createElement("span");
            break;
        case EditorNodeType.TEXT:
            child = document.createTextNode(node.content || "");
            break;
    }
    if (child) {
        if (node.selectionAnchor) {
            selectionAnchor = child;
            anchorOffset = node.anchorOffset;
            console.log("setting andhor", selectionAnchor, anchorOffset);
        }
        if (node.decorators.length > 0) {
            for (let i = 0; i < node.decorators.length; i++) {
                const decorator = node.decorators[i];
                const el = child;
                switch (decorator) {
                    case Decorator.BOLD:
                        el.style.fontWeight = "bold";
                        break;
                    case Decorator.ITALIC:
                        el.style.fontStyle = "italic";
                }
            }
        }
        if (parent && child)
            parent.appendChild(child);
        if (!parent && child) {
            root = child;
        }
        if (node.children.length > 0) {
            for (let i = 0; i < node.children.length; i++) {
                const childNode = node.children[i];
                const treeResult = buildHTMLTree(childNode, child);
                if (treeResult.selectionAnchor) {
                    selectionAnchor = treeResult.selectionAnchor;
                    anchorOffset = treeResult.anchorOffset;
                    console.log("setting anchor from child");
                }
            }
        }
    }
    else {
        console.error("unknown el detected", node);
    }
    return { root: root, selectionAnchor, anchorOffset };
}
export const commitNextTick = (input) => {
    setTimeout(() => {
        commit(input);
    }, 0);
};
export const commit = (input) => {
    const snapshot = convert(input);
    const editHistory = getEditHistory();
    // reset to cuirrent pointer
    let { stack, index } = editHistory;
    if (index < stack.length - 1) {
        stack = stack.slice(0, index + 1);
    }
    stack.push(snapshot);
    index = editHistory.stack.length - 1;
    // max 50 changes
    if (stack.length > 50) {
        stack.shift();
    }
    console.log("committing", stack, index);
    localStorage.setItem("lilo:editHistory", JSON.stringify({ stack, index }));
};
// move the pointer down one, return what's in storage and re-save
export const undo = () => {
    console.log("undo");
    const editHistory = getEditHistory();
    const { stack, index } = editHistory;
    const newIndex = index - 1;
    if (newIndex > -1) {
        const item = stack[newIndex];
        localStorage.setItem("lilo:editHistory", JSON.stringify({ stack, index: newIndex }));
        return buildHTMLTree(item, null);
    }
    return {
        root: document.createElement("div"),
        anchorOffset: 0,
        selectionAnchor: null,
    };
};
// move the pointer up one and return what's in storage and re-save
export const redo = () => {
    console.log("redo");
    const editHistory = getEditHistory();
    const { stack, index } = editHistory;
    const newIndex = index + 1;
    if (newIndex < stack.length) {
        const item = stack[newIndex];
        localStorage.setItem("lilo:editHistory", JSON.stringify({ stack, index: newIndex }));
        return buildHTMLTree(item, null);
    }
    return {
        root: document.createElement("div"),
        anchorOffset: 0,
        selectionAnchor: null,
    };
};
function getEditHistory() {
    const editHistoryJson = localStorage.getItem("lilo:editHistory");
    if (editHistoryJson != null) {
        const editHistory = JSON.parse(editHistoryJson);
        return editHistory;
    }
    else {
        return {
            stack: [],
            index: 0,
        };
    }
}
export const setCursor = (anchor, offset) => {
    console.log("setting curosor", anchor, offset);
    const selection = window.getSelection();
    if (selection) {
        selection.removeAllRanges();
        const range = new Range();
        range.setStart(anchor, offset);
        range.collapse(false);
        selection.addRange(range);
        // selection.collapseToEnd();
    }
};

import { LinkPreviewFieldsFragment } from "./fragments";
import gql from "graphql-tag";

export const LINK_PREVIEW_QUERY = gql`
  query GetLinkPreview($target: String!) {
    linkPreview(target: $target) {
      preview {
        ...LinkPreviewFields
      }
    }
  }

  ${LinkPreviewFieldsFragment}
`;
